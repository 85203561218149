@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;600;700;800;900&display=swap");

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.personalizeFrom .form-col {
    padding-right: calc(var(--bs-gutter-x) * 0.3) !important;
    padding-left: calc(var(--bs-gutter-x) * 0.3) !important;
}

.personalizeFrom form .form-control not input[type="file"] {
    height: 50px;
    border-radius: 5px;
    /* border: 2px solid #808080; */
    background-color: #fff;
    box-shadow: unset !important;
}

.personalizeFrom form .form-control::placeholder {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.3px;
    text-align: left;
    color: #808080;
}

.personalizeFrom form .form-check .form-check-input[type="checkbox"] {
    border-radius: 3px !important;
    box-shadow: unset !important;
}

.personalizeFrom form .form-check .form-check-input[type="checkbox"]:checked {
    background-color: #ffff !important;
}
.personalizeFrom form .form-check .form-check-input:checked {
    border-color: #0061a8 !important;
    box-shadow: unset !important;
}

.personalizeFrom form .form-check .form-check-input:checked[type="checkbox"] {
    background-image: url(../../assets/icons/check.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.personalizeFrom form .form-check .form-check-label {
    cursor: pointer;
}

.personalizeFrom .logoOption {
    padding: 20px 0px;
}

.personalizeFrom .logoOption h4 {
    line-height: 2.06 !important;
}

.personalizeFrom .accountBox {
    width: 100%;
    height: 230px;
    margin: 35px 0px 20px;
    border-radius: 10px;
    border: solid 2px #4fa519;
    background-color: #edf0e6;
}

.personalizeFrom .checkComment {
    width: 100%;
    height: 119.5px;
    padding: 20px;
    border-radius: 5px;
    border: solid 2px #808080;
    background-color: #fff;
    outline: none;
    font-size: 13px;
    font-family: "Montserrat", sans-serif;
}

.personalizeFrom form .checkComment::placeholder {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.3px;
    text-align: left;
    color: #808080;
}

.selectedBackground {
    border: 1px solid #0061a8;
}

.custom-radio {
    margin-right: 5px;
}