@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;600;700;800;900&display=swap");

@font-face {
    font-family: micrenc;
    src: url(../../assets/fonts/micrenc.ttf);
}

.personalize_wrapper {
    display: flex;
    justify-content: space-between;
}

.personalize_wrapper .personalize_tabs {
    background: #ffffff;
    display: flex;
    justify-content: flex-end;
    padding: 80px 50px;
    padding-bottom: 0;
    width: 50%;
    height: 100vh;
    overflow: auto;
}

.personalize_wrapper .personalize_tabs .tab-content {
    padding-bottom: 80px;
}

.personalize_wrapper .personalize_preview {
    background: #fafafa;
    padding: 80px 50px;
    display: flex;
    justify-content: flex-start;
    width: 50%;
    height: 100%;
    min-height: 100vh;
    position: sticky;
    top: 0;
}

.personalize_wrapper .personalize_tabs .tabsInner,
.personalize_wrapper .personalize_preview .previewInner {
    width: 85%;
}

.personalize_tabs .tabsInner .nav-tabs {
    background: #f5f5f5;
    border: none !important;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
}

.personalize_tabs .tabsInner .nav-tabs .nav-item .nav-link {
    color: #808080;
    border: none !important;
    margin: 0 !important;
    padding: 0px 18px;
    height: 50px;
}

.personalize_tabs .tabsInner .nav-tabs .nav-link.passed {
    background: #73b450;
}
.personalize_tabs .tabsInner .nav-tabs .nav-link.active {
    background: #4fa519;
}
.personalize_tabs .tabsInner .nav-tabs .nav-link.passed,
.personalize_tabs .tabsInner .nav-tabs .nav-link.active {
    color: #ffff;
    border-radius: 5px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);
}

.personalize_tabs .tabsInner .tab-content h4 {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    line-height: 3.06;
    letter-spacing: -0.36px;
    text-align: left;
    color: #000;
}

.personalize_tabs .tabsInner .tab-content .personalizeHeading {
    padding-top: 10px;
}

.personalize_tabs .tabsInner .tab-content .nextBtn {
    padding-top: 20px;
}

.personalize_tabs .tabsInner .tab-content .nextBtn button {
    width: 235px;
    height: 50px;
    border: 1px solid #0061a8;
    border-radius: 5px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);
    background-color: #0061a8;
    color: #ffffff;
}

.personalize_preview .previewInner .FrontBack_Btns {
    display: flex;
    justify-content: flex-end;
}

/* .personalize_preview .previewInner .FrontBack_Btns button {
    width: 100px;
    height: 50px;
    border-radius: 5px;
    border: solid 2px #e2e2e2;
    background: transparent;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.3px;
    text-align: center;
    color: #808080;
} */

.personalize_preview .previewInner .FrontBack_Btns button:nth-child(1) {
    margin-right: 10px;
}

.personalize_preview .previewInner .preview_image {
    width: 100%;
    /* height: 50%; */
    /* height: 100%; */
    margin: 40px 0px;
    position: absolute;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 10px;
    /* border: solid 2px #4fa519; */
    background-color: transparent;
    z-index: 10;
    overflow: hidden;
    /* background-color: #edf0e6; */
}

.personalize_preview .previewInner .previewNote {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 500;
    font-style: italic;
    line-height: 1.54;
    letter-spacing: -0.26px;
    text-align: left;
    color: #808080;
    margin: 25px 0px;
}

.check-price {
    float: left;
    color: rgb(79, 165, 25);
    font-size: 24px;
    font-weight: 700;
}

button[disabled] 
{
    pointer-events: unset !important;
    cursor: not-allowed;
}

@media screen and (max-width: 991px) {
    .personalize_wrapper .personalize_tabs {
        justify-content: center;
    }
    .personalize_wrapper .personalize_preview {
        justify-content: center;
    }
}

@media screen and (max-width: 767px) {
    .personalize_wrapper .personalize_tabs {
        padding: 80px 0px !important;
    }
    .personalize_wrapper .personalize_preview {
        padding: 80px 0px !important;
    }
}

.flip-card {
    background-color: transparent;
    width: 100%;
    height: 100%;
    perspective: 1000px;
}

@keyframes slidingstep1 {
    0% {
        top: -100%;
        opacity: 0;
    }
    100% {
        top: 0%;
        opacity: 1;
    }
}
.flip-card-inner .canvas-container {
    animation-name: slidingstep1;
    animation-duration: 0.3s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-timing-function: ease-out;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.4s;
    transform-style: preserve-3d;
}

.flip-card.back .flip-card-inner {
    transform: rotateY(180deg);
}

.preview_image:last-of-type {
    transform: rotateY(180deg);
}

.form-item-with-bold-input-group > .input-group-text:last-child {
    border-left-width: 0;
    padding-left: 0;
}
/* #uncontrolled-tab-example-tabpane-1 {
    margin: auto;
    width: 0%;
    padding: 10px;
} */